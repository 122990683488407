import React from "react"
import { graphql } from "gatsby"

import { MDXRenderer } from "gatsby-plugin-mdx"

import Layout from '../../components/common/layout';
import SEO from '../../components/common/seo';
import BlogPostCard from "../../components/blog/blogpostcard";
import BlogPostWordpressCard from "../../components/blog/blogpostcardWordpress";
import AuthorHeader from "../../components/blog/authorheader";

import "../../styles/blogpost.css"


// Page for an author
export default function AuthorWordpress({data}) {
  const author = data.author.nodes[0]
  const posts = author.posts.nodes;
  return (
    <Layout
      // pageTitle={author.frontmatter.name}
      enableShare={true}
    >
      <SEO title={author.firstName} description={author.description}
        generateImage={true} path={`/author/${author.slug}/`}
      />

      <div className="max-w-5xl mx-auto">
        <div className="content-body markdown-body">
          <h1 className="mb-0 text-center">{author.firstName}</h1>
          {/* <div className="text-center mt-0">Community Guest Author</div> */}
        </div>

        {/* <div className="mt-8">
          <div className="text-black flex bg-yellow-100 text-rose-500 p-4 rounded-2xl items-center">
            {author.avatar?.url ? (
              <img className="h-24 w-24 bg-yellow-200 rounded-full object-cover mr-6" src={author.avatar.url} alt={author.firstName} />
            ) : ''}
            
            <div>
              <div className="text-lg font-medium">{author.description}</div>

              <div className="flex space-x-2 mt-2">
                {author.frontmatter.twitter ? (
                  <a href={author.frontmatter.twitter} target="_blank" className="underline">Twitter</a>
                ) : ''}
                {author.frontmatter.linkedin ? (
                  <a href={author.frontmatter.linkedin} target="_blank" className="underline">LinkedIn</a>
                ) : ''}
                {author.frontmatter.website ? (
                  <a href={author.frontmatter.website} target="_blank" className="underline">Website</a>
                ) : ''}
              </div>
            </div>
          </div>
        </div> */}

        {/*<div className="content-body markdown-body">
          <MDXRenderer>{author.body}</MDXRenderer>
        </div>*/}

        <div className="mt-12">
          <div id="recent" className="">
            <h2 className="text-2xl font-semibold flex items-center">Recently Published</h2>
            <div className="">
              <ul className={`mt-4 grid sm:grid-cols-3 gap-x-8 gap-y-4`}>
                {posts.map((node, index) => (
                  <BlogPostWordpressCard key={node.slug} node={node} mini={false}/>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>

      
    </Layout>
  )
}
export const pageQuery = graphql`
  query($slug: String!) {
    author:allWpUser(limit: 1, filter: {slug: {eq: $slug}}) {
      nodes {
        id
        slug
        description
        firstName
        avatar {
          url
        }
        posts {
          nodes {
            id
            date
            slug
            title
            status
            excerpt
            dateGmt
            tags {
              nodes {
                name
                slug
                count
              }
            }
            author {
              node {
                name
                slug
                firstName
                avatar {
                  url 
                }
              }
            }
          }
        }
      }
    }
  }
`