/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { Helmet } from 'react-helmet-async';
import { useStaticQuery, graphql } from "gatsby"

import shareCardImage from "../../images/share-card.png"


function SEO({ 
  description, 
  lang, 
  meta, 
  title, 
  dontIndex, 
  generateImage, 
  generateImageElement, 
  path, 
  imageUrl,
  imageSize,
  schema,
}) {
  // imageUrl is optional
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            siteUrl
          }
        }
      }
    `
  )

  var metaTitle = title;
  if (metaTitle.length < 30 && !metaTitle.includes('Hive Index')){
    metaTitle += ' | Hive Index'
  }

  var metaDescription = description || site.siteMetadata.description;
  if (metaDescription.length < 90){
    metaDescription += ' Browse communities today, or submit your own for free.'
  }

  if (metaDescription.length > 160){
    metaDescription = metaDescription.slice(0, 160)
  }

  const domain = typeof window !== 'undefined' ? window.location.protocol + `//` + window.location.host : `https://thehiveindex.com`
  var url = typeof window !== 'undefined' ? domain + window.location.pathname : '';  // constructs current url without the query params
  if (!url && domain && path){
    url = domain + path // construct url if we have a path passed in manually
  }

  // share image is either passed in, or it defaults to share card image
  var shareImageUrl = domain + shareCardImage
  if (imageUrl){
    shareImageUrl = imageUrl
  }

  // custom url which is just a screenshot of the page (if using this option)
  if (url && generateImage && process.env.NODE_ENV === "production"){
    shareImageUrl = `https://api.apiflash.com/v1/urltoimage?access_key=14d08832cb4347cfb2dd4598aea0985c&height=675&width=1200&ttl=1296000&response_type=image&format=png${generateImageElement ? '&element=' + encodeURIComponent(generateImageElement) : ''}&url=` + encodeURIComponent(url);
  }

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={metaTitle}
      titleTemplate={`%s`}
    >
      <link rel="canonical" href={url} />
      <meta name="description" content={metaDescription} />
      
      <meta name="title" content={metaTitle} />
      <meta name="og:title" content={metaTitle} />
      <meta name="og:url" content={url} />
      <meta name="og:description" content={metaDescription} />
      <meta name="og:type" content="website" />
      <meta name="og:site_name" content="Hive Index" />
      <meta name="og:image" content={shareImageUrl} />
      
      {/* twitter */}
      {imageSize === "small" ? (
        <meta name="twitter:card" content="summary" />
      ) : (
        <meta name="twitter:card" content="summary_large_image" />
      )}
      {site.siteMetadata.author ? (
        <meta name="twitter:creator" content={site.siteMetadata.author} />
      ) : ''}
      <meta name="twitter:title" content={metaTitle} />
      <meta name="twitter:description" content={metaDescription} />
      <meta name="twitter:image" content={shareImageUrl} />
      <meta name="twitter:domain" content="thehiveindex.com" />
      <meta name="twitter:url" content={url} />

      {dontIndex ? (
        <meta name="robots" content="noindex, follow" />
      ) : (
        <meta name="robots" content="index, follow" />
      )}

      {schema ? (
        <script type="application/ld+json">
          {JSON.stringify(schema)}
        </script>
      ) : ''}
    </Helmet>
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default SEO
