import React from "react"
import { StaticQuery, graphql } from "gatsby"
import { Link } from "gatsby"

import BlogPostWordpressCard from "./blogpostcardWordpress";


// shows 3 most recent blog posts (usually at footer of the page)
export default function RecentReads({skipSlug}) {  

  return (
    <StaticQuery
      query={graphql`
        query RecentPostsQuery {
          recent: allWpPost(
            sort: {fields: date, order: DESC},
            filter: {status: {eq: "publish"}},
            limit: 4
          ) {
            nodes {
              id
              date
              slug
              title
              status
              excerpt
              dateGmt
              tags {
                nodes {
                  name
                  slug
                  count
                }
              }
              author {
                node {
                  name
                  slug
                  firstName
                  avatar {
                    url
                  }
                }
              }
            }
          }
        }
      `}
      render={data => {
        // const { recent } = data;  // data.mdx holds your post data

        return (
          <div className="mt-12">
            <div className="">
              <div className="flex items-center">
                <h2 className="text-xl font-semibold flex items-center">Other blog posts</h2>
                <div className="ml-auto">
                  <Link to="/blog/" className="opacity-50 hover:opacity-100 hover:underline cursor-pointer">View All</Link>
                </div>
              </div>
              <div className="">
                <ul className={`mt-4 grid sm:grid-cols-3 gap-x-8 gap-y-4`}>
                  {data.recent.nodes.filter(node => node.slug !== skipSlug).slice(0, 3).map((node, index) => (
                    <BlogPostWordpressCard key={node.slug} node={node} mini={true}/>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        )
      }}
    />
  )
}