import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

const Image = ({ fileName, alt, style, className, width, height, objectFit, loading }) => {
  const data = useStaticQuery(graphql`
    query {
      allFile(filter: { sourceInstanceName: { eq: "images" } }) {
        nodes {
          name
          childImageSharp {
            gatsbyImageData(
              layout: CONSTRAINED
              formats: [AUTO, WEBP]
              placeholder: BLURRED
            )
          }
        }
      }
    }
  `)

  // Match the image file by name (strip extension)
  const imageNode = data.allFile.nodes.find(node => node.name === fileName.split('.')[0])

  if (!imageNode || !imageNode.childImageSharp) {
    console.error(`Image not found: ${fileName}`)
    return null
  }

  const image = getImage(imageNode.childImageSharp)

  return (
    <GatsbyImage
      image={image}
      alt={alt}
      className={className}
      objectFit={objectFit || 'cover'}
      style={{
        ...style,
        width: width ? `${width}px` : 'auto',
        height: height ? `${height}px` : 'auto',
      }}
      loading={loading || 'lazy'}
    />
  )
}

export default Image
