import React from "react"
import { graphql, Link } from "gatsby"

import { MDXRenderer } from "gatsby-plugin-mdx"

import Layout from '../../components/common/layout';
import SEO from '../../components/common/seo';
// import CTA from '../components/blog/cta';
// import Recommended from '../components/blog/recommended';
import AuthorHeader from '../../components/blog/authorheader';
import Subscribe from '../../components/common/subscribe';
import RecentReads from "../../components/blog/recentReads";

import "../../styles/blogpost.css"


// Page for a wordpress post
export default function WpBlogPost({data}) {
  const { allWpPost } = data;
  const post = allWpPost.nodes[0]

  if (!post || !post.title){
    return <div>No post found</div>
  }

  const author = post.author.node

  return (
    <Layout
      pageTitle={post.title}
      enableShare={true}
      // backgroundImage={post.featuredImage?.node?.link}
      // backgroundImageAlt={post.title}
    >
      <SEO title={post.title} description={post.excerpt ? post.excerpt.replace(/<[^>]+>/g, '') : ''}
        path={`/blog/${post.slug}/`}
        imageUrl={post.featuredImage?.node?.link}
        generateImage={post.featuredImage ? false : true}
      />
      
      <div className="content-body markdown-body max-w-5xl mx-auto">
        {post.featuredImage?.node?.link ? (
          <div className="text-center mb-4">
            <img src={post.featuredImage?.node?.link} alt={post.title} className="w-full rounded-lg max-w-xl mx-auto" />
          </div>
        ) : ''}

        {post.dateGmt ? (
          <div className="text-center mt-0">Published {post.dateGmt.split('T')[0]}</div>
        ) : ''}
        {author ? (
          <div className="text-center mt-0">
            <div className="bold">By <Link to={`/author/${author.slug}/`}>{author.name}</Link></div>
          </div>
        ) : ''}

        <div
          className="mt-4 wordpress-content"
          dangerouslySetInnerHTML={{__html: post.content}}
        />
        
        <div className="flex">
          <Link to={`/author/${author.slug}/`} className="ml-auto">- {author.firstName}</Link>
        </div>
        
        <hr className="bg-gray-100"/>
        <div>
          <Subscribe />
        </div>
      </div>

      <div>
        <RecentReads skipSlug={post.slug} />
      </div>
    </Layout>
  )
}
export const pageQuery = graphql`
  query($slug: String!) {
    allWpPost(
      limit: 1,
      filter: {slug: {eq: $slug}}
    ) {
      nodes {
        id
        date
        slug
        title
        status
        dateGmt
        tags {
          nodes {
            name
            slug
            count
          }
        }
        author {
          node {
            name
            firstName
            slug
            description
            avatar{
              url
            }
          }
        }
        featuredImage {
        node {
          link
          }
        }
        modified
        content
        excerpt
      }
    }
  }
`